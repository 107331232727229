import React, { useEffect, useState } from 'react';
import {
    Card,
    CardBody,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    Label,
    Alert,
    NavLink,
    Button,
    Row,
    FormFeedback,
    TabPane,
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { useMutation, useQueryClient } from 'react-query';
import { getAllRelationshipsApi } from 'api/report';
import * as Yup from "yup";
import moment from 'moment'
import { useFormik } from "formik";
import { useParams } from 'react-router-dom';
import { addNextOfKin, addMember, getAttachmentApi, uploadDepeMedia, getNextOfKinByIdApi, updateNextOfKin } from 'api/member';

const AddNextOfKin = ({ onChange, member, memberData, onNext, setKin, kin, mutateMember }) => {
    const queryClient = useQueryClient();
    const [file, setSelectedFile] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const { id } = useParams();
    const { mutate: mutateRelationships,
        isLoading: isLoadingRelationships,
        data: relationshipsData, } = useMutation(
            (payload) => getAllRelationshipsApi(payload),
            {
                onSettled: () => {
                    queryClient.invalidateQueries('/public/um/setup/relationship');
                },
            }
        );

    const { mutate: mutateNextOfKin,
        isLoading: isLoadingNextOfKins,
        data: nextOfKins } = useMutation(
            (payload) => getNextOfKinByIdApi(payload),
            {
                onSuccess: res => {
                    console.log(res);
                    if (res?.length > 0) {
                        const kin = res?.find(k => k.status == "ACTIVE")
                        setKin(kin);
                        kin?.nextOfKinId && mutateAttachements(kin?.nextOfKinId)
                    }
                },
                onSettled: () => {
                    queryClient.invalidateQueries('/um/nextofkin');
                },
            }
        );

    const { mutate: mutateAttachements,
        isLoading: isLoadingAttachements,
        data: attachements } = useMutation(
            (payload) => getAttachmentApi(payload),
            {
                onSettled: () => {
                    queryClient.invalidateQueries('/um/dependent/attachment');
                },
            }
        );

    useEffect(() => {
        if (member?.memberId) {
            mutateNextOfKin({ memberId: member?.memberId, status: "Active" });
        }
    }, [member?.memberId])

    const handleFileChange = e => {
        setSelectedFile(e.target.files[0]);
        validation.handleChange(e);
    };

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            firstName: kin?.firstName || '',
            lastName: kin?.lastName || '',
            email: kin?.email || '',
            relationship: kin?.relationship || '',
            identificationType: kin?.identificationType || '',
            identificationNumber: kin?.identificationNumber || '',
            mobileNumber: kin?.mobileNumber || '',
            gender: kin?.gender || '',
            dob: kin?.dob ? moment(kin?.dob).format('DD-MM-YYYY') : ''
            // nextofkinIdentification: kin?.nextofkinIdentification || '',
        },
        validationSchema: Yup.object({
            firstName: Yup.string().required("Please Enter First Name"),
            lastName: Yup.string().required("Please Enter Last Name"),
            identificationType: Yup.string().required("Please Select ID Type"),
            identificationNumber: Yup.string()
            .required("Please Enter ID")
            .max(9, "ID cannot be more than 9 digits"),
            mobileNumber: Yup.string()
            .required("Please Enter Mobile Number")
            .matches(/^\d+$/, "Phone number must contain only digits"),
            relationship: Yup.string().required("Please Select Relationship"),
            gender: Yup.string().required("Please Select Gender"),
            dob: Yup.string().required("Please Select DOB")
            // nextofkinIdentification: Yup.string().required("Please Select ID document"),
        }),
        onSubmit: async (values, { resetForm }) => {
            console.log(values);
            console.log(member);

            try {
                await validation.validateForm();

                let id = null;
                let memberId = null;
                if (!member) {
                    await addMember(memberData).then(res => {
                        memberId = res?.data;
                        mutateMember(memberId);
                    });
                }
                if (!kin?.nextOfKinId) {
                    let kinData = {
                        ...values,
                        memberId: member?.memberId || memberId,
                        dob: moment(values.dob).format("DD-MM-YYYY"),
                        status: "Active"
                    };
                    delete kinData.nextofkinIdentification;
                    const res = await addNextOfKin(kinData);
                    id = res?.data?.id;

                } else {
                    id = kin?.nextOfKinId;
                }
                if (file) {
                    await uploadDepeMedia(id, file);
                }

                resetForm();
                onNext();

            } catch (error) {
                setErrorMessage(error?.message)
                console.error("Form validation error:", error);
            }
        },
    });


    useEffect(() => {
        mutateRelationships({
        });
    }, []);

    return (
        <div>
            {errorMessage && errorMessage ? (
                <Alert color="danger">{errorMessage}</Alert>
            ) : null}
            <Form autoComplete="off" onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
            }}>
                <Row>
                    <Col lg="6">
                        <FormGroup className="mb-3">
                            <Label for="firstName">
                                First Name
                            </Label>
                            <Input
                                name="firstName"
                                type="text"
                                className="form-control"
                                id="firstName"
                                placeholder="Enter First Name"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.firstName || ""}
                                invalid={
                                    validation.touched.firstName && validation.errors.firstName ? true : false
                                }
                            />
                            {validation.touched.firstName && validation.errors.firstName ? (
                                <FormFeedback type="invalid">{validation.errors.firstName}</FormFeedback>
                            ) : null}
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup className="mb-3">
                            <Label for="lastName">
                                Last Name
                            </Label>
                            <Input
                                name="lastName"
                                type="text"
                                className="form-control"
                                id="lastName"
                                placeholder="Enter Last Name"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.lastName || ""}
                                invalid={
                                    validation.touched.lastName && validation.errors.lastName ? true : false
                                }
                            />
                            {validation.touched.email && validation.errors.email ? (
                                <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                            ) : null}
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup className="mb-3">
                            <Label>Gender</Label>
                            <Input type='select'
                                className="form-select"
                                name="gender"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.gender || ""}
                                invalid={
                                    validation.touched.gender && validation.errors.gender ? true : false
                                }
                            >
                                <option defaultValue>
                                    Select Gender...
                                </option>
                                <option value="Female">Female</option>
                                <option value="Male">Male</option>
                                <option value="Intersex">Intersex</option>
                                <option value="Other">Other</option>
                            </Input>
                            {validation.touched.gender && validation.errors.gender ? (
                                <FormFeedback type="invalid">{validation.errors.gender}</FormFeedback>
                            ) : null}
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup className="mb-3">
                            <Label for="email">
                                Enter Email Address
                            </Label>
                            <Input
                                name="email"
                                type="email"
                                className="form-control"
                                id="email"
                                placeholder="Enter Email Address"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.email || ""}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col lg="6">
                        <FormGroup className="mb-3">
                            <Label>Identification Type</Label>
                            <Input type='select' className="form-select"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                name="identificationType"
                                value={validation.values.identificationType || ""}
                                invalid={
                                    validation.touched.identificationType && validation.errors.identificationType ? true : false
                                }

                            >
                                <option defaultValue>
                                    Select ID Type...
                                </option>
                                <option value="idNumber">National ID Card</option>
                                <option value="Passport">Passport</option>
                            </Input>
                            {validation.touched.identificationType && validation.errors.identificationType ? (
                                <FormFeedback type="invalid">{validation.errors.identificationType}</FormFeedback>
                            ) : null}
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup className="mb-3">
                            <Label for="basicpill-pancard-input5">
                                Identification Number
                            </Label>
                            <Input
                                name="identificationNumber"
                                type="text"
                                className="form-control"
                                id="basicpill-pancard-input5"
                                placeholder="Enter Your Identification Number"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.identificationNumber || ""}
                                invalid={
                                    validation.touched.identificationNumber && validation.errors.identificationNumber ? true : false
                                }
                            />
                            {validation.touched.identificationNumber && validation.errors.identificationNumber ? (
                                <FormFeedback type="invalid">{validation.errors.identificationNumber}</FormFeedback>
                            ) : null}
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col lg="6">
                        <FormGroup className="mb-3">
                            <Label for="mobileNumber">
                                Phone Number
                            </Label>
                            <Input
                                name="mobileNumber"
                                type="text"
                                className="form-control"
                                id="mobileNumber"
                                placeholder="Enter Next of Kin Mobile Number"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.mobileNumber || ""}
                                invalid={
                                    validation.touched.mobileNumber && validation.errors.mobileNumber ? true : false
                                }
                            />
                            {validation.touched.mobileNumber && validation.errors.mobileNumber ? (
                                <FormFeedback type="invalid">{validation.errors.mobileNumber}</FormFeedback>
                            ) : null}
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup className="mb-3">
                            <Label>Relationship</Label>
                            <Input className="form-select"
                                name="relationship"
                                type='select'
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.relationship || ""}
                                invalid={
                                    validation.touched.relationship && validation.errors.relationship ? true : false
                                }
                            >
                                <option defaultValue value={''}>
                                    Select Relationship...
                                </option>
                                {
                                    relationshipsData && relationshipsData.map(item => (
                                        <option key={item.id} value={item.name}>
                                            {item.name}
                                        </option>
                                    ))
                                }
                            </Input>
                            {validation.touched.relationship && validation.errors.relationship ? (
                                <FormFeedback type="invalid">{validation.errors.relationship}</FormFeedback>
                            ) : null}
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col lg="6">
                        <FormGroup className="mb-3">
                            <Label for="basicpill-pancard-input5">
                                Date Of birth
                            </Label>
                            <Input
                                name="dob"
                                max={moment().subtract(18, "years").format("DD-MM-YYYY")}
                                type="date"
                                className="form-control"
                                id="basicpill-pancard-input5"
                                placeholder="Select Date of Birth"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.dob || ""}
                                invalid={
                                    validation.touched.dob && validation.errors.dob ? true : false
                                }
                            />
                            {validation.touched.dob && validation.errors.dob ? (
                                <FormFeedback type="invalid">{validation.errors.dob}</FormFeedback>
                            ) : null}
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <div className="mb-3">
                            <Label for="nextofkinIdentification">
                                Upload Next of KIN Identification Document
                            </Label>
                            <Input
                                type="file"
                                name="nextofkinIdentification"
                                className="form-control"
                                id="nextofkinIdentification"
                                placeholder="Upload Next of KIN Identification Document"
                                onChange={e => handleFileChange(e)}
                                value={validation.values.nextofkinIdentification || ''}
                                onBlur={validation.handleBlur}
                                // invalid={
                                //     validation.touched.nextofkinIdentification && validation.errors.nextofkinIdentification ? true : false
                                // }
                            />
                            {/* {validation.touched.nextofkinIdentification && validation.errors.nextofkinIdentification ? (
                                <FormFeedback type="invalid">{validation.errors.nextofkinIdentification}</FormFeedback>
                            ) : null} */}
                        </div>
                        {
                            attachements?.length > 0 ? <div>
                                <span className="me-3">Name: <strong>{attachements[0].type}</strong></span>
                                <a href={attachements[0].url} target="_blank" rel="noopener noreferrer">{attachements[0].name}</a>
                            </div>
                                : <div />
                        }

                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col md="6">
                        <FormGroup>
                            <Button
                                type="submit"
                                color="primary"
                                block
                            >
                                SAVE NEXT OF KIN
                            </Button>
                        </FormGroup>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

export default AddNextOfKin
